import { pageSizeOptions } from '@app/appConstants';

export const activityList = [
  {
    key: 'potentialRevenue',
    value: 'Potential Revenue',
    multiLanguageKey: 'audience.potentialRevenue',
  },
  {
    key: 'processingVolmue',
    value: 'Processing Volume',
    multiLanguageKey: 'audience.processingVolmue',
  },
  {
    key: 'merchantCount',
    value: 'Merchants',
    multiLanguageKey: 'audience.merchantCount',
  },
  {
    key: 'marketShare',
    value: 'Market Share',
    multiLanguageKey: 'audience.marketShare',
  },
];
export const errorMessage = 'Something went wrong';

export const merchantGridDefined = [
  { displayName: '', dataFieldName: 'checkbox' },
  {
    displayName: 'Location',
    multiLanguageKey: 'audience.merchant',
    dataFieldName: 'companyName',
    isSortable: true,
  },
  {
    displayName: 'Potential Revenue',
    multiLanguageKey: 'audience.table.potentialRevenue',
    dataFieldName: 'potentialRevenue',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'potentialRevenuePercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Competitor Processing Volume',
    multiLanguageKey: 'audience.table.competitorProcessingVolume',
    dataFieldName: 'competitorProcessingVolume',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'competitorProcessingPercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Bank Officer',
    multiLanguageKey: 'audience.table.bankOfficer',
    dataFieldName: 'branchOfficer',
    isSortable: true,
  },
  {
    displayName: 'Provider',
    multiLanguageKey: 'audience.table.provider',
    dataFieldName: 'provider',
    isSortable: true,
  },
  {
    displayName: 'Industry',
    multiLanguageKey: 'shared.common.industry',
    dataFieldName: 'industry',
    isSortable: true,
  },
  {
    displayName: 'Active Branch',
    multiLanguageKey: 'audience.table.activeBranch',
    dataFieldName: 'branch',
    isSortable: true,
  },
  {
    displayName: 'Active Campaign',
    multiLanguageKey: 'audience.table.activeCampaign',
    dataFieldName: 'campaignName',
  },
  {
    displayName: 'Sales Stage',
    multiLanguageKey: 'audience.table.salesStage',
    dataFieldName: 'salesStage',
  },
];

export const locationGridDefined = [
  { displayName: '', dataFieldName: 'checkbox' },
  {
    displayName: 'Location',
    multiLanguageKey: 'shared.common.text.location',
    dataFieldName: 'state',
    isSortable: true,
    fieldType: 'link',
  },
  {
    displayName: 'Potential Revenue',
    multiLanguageKey: 'audience.table.potentialRevenue',
    dataFieldName: 'potentialRevenue',
    isSortable: true,
    fieldType: 'currency',
    hasPercentage: true,
    dataFieldNameForPercentage: 'potentialRevenuePercentage',
  },
  {
    displayName: 'Competitor Processing Volume',
    multiLanguageKey: 'audience.table.competitorProcessingVolume',
    dataFieldName: 'competitorProcessingVolume',
    isSortable: true,
    fieldType: 'currency',
    hasPercentage: true,
    dataFieldNameForPercentage: 'competitorProcessingPercentage',
  },
  {
    displayName: 'Competitor Merchants',
    multiLanguageKey: 'audience.table.competitorMerchants',
    dataFieldName: 'competitorMerchants',
    isSortable: true,
    fieldType: 'number',
  },
  {
    displayName: 'Revenue / Account',
    multiLanguageKey: 'audience.table.revenueAccount',
    dataFieldName: 'revenueAccount',
    isSortable: true,
    fieldType: 'currency',
  },
  {
    displayName: 'Market Share',
    multiLanguageKey: 'audience.table.marketShare',
    dataFieldName: 'marketShare',
    isSortable: true,
    fieldType: 'percent',
  },
];

export const officerGridDefined = [
  { displayName: '', dataFieldName: 'checkbox' },
  {
    displayName: 'Bank Officer',
    multiLanguageKey: 'audience.table.bankOfficer',
    dataFieldName: 'branchOfficer',
    isSortable: true,
  },
  {
    displayName: 'Potential Revenue',
    multiLanguageKey: 'audience.table.potentialRevenue',
    dataFieldName: 'potentialRevenue',
    isSortable: true,
    fieldType: 'currency',
    hasPercentage: true,
    dataFieldNameForPercentage: 'potentialRevenuePercentage',
  },
  {
    displayName: 'Competitor Processing Volume',
    multiLanguageKey: 'audience.table.competitorProcessingVolume',
    dataFieldName: 'competitorProcessingVolume',
    isSortable: true,
    fieldType: 'currency',
    hasPercentage: true,
    dataFieldNameForPercentage: 'competitorProcessingPercentage',
  },
  {
    displayName: 'Competitor Merchants',
    multiLanguageKey: 'audience.table.competitorMerchants',
    dataFieldName: 'competitorMerchants',
    isSortable: true,
    fieldType: 'number',
  },
  {
    displayName: 'Revenue / Account',
    multiLanguageKey: 'audience.table.revenueAccount',
    dataFieldName: 'revenueAccount',
    isSortable: true,
    fieldType: 'currency',
  },
  {
    displayName: 'Market Share',
    multiLanguageKey: 'audience.table.marketShare',
    dataFieldName: 'marketShare',
    isSortable: true,
    fieldType: 'percent',
  },
];
export const branchGridDefined = [
  { displayName: '', dataFieldName: 'checkbox' },
  {
    displayName: 'Location',
    multiLanguageKey: 'shared.common.branch',
    dataFieldName: 'branch',
    isSortable: true,
    fieldType: 'link',
  },
  {
    displayName: 'Potential Revenue',
    multiLanguageKey: 'audience.table.potentialRevenue',
    dataFieldName: 'potentialRevenue',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'potentialRevenuePercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Competitor Processing Volume',
    multiLanguageKey: 'audience.table.competitorProcessingVolume',
    dataFieldName: 'competitorProcessingVolume',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'competitorProcessingPercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Competitor Merchants',
    multiLanguageKey: 'audience.table.competitorMerchants',
    dataFieldName: 'competitorMerchants',
    isSortable: true,
    fieldType: 'number',
  },
  {
    displayName: 'Revenue / Account',
    multiLanguageKey: 'audience.table.revenueAccount',
    dataFieldName: 'revenueAccount',
    isSortable: true,
    fieldType: 'currency',
  },
  {
    displayName: 'Market Share',
    multiLanguageKey: 'audience.table.marketShare',
    dataFieldName: 'marketShare',
    isSortable: true,
    fieldType: 'percent',
  },
];
export const competitorGridDefined = [
  { displayName: '', dataFieldName: 'checkbox' },
  {
    displayName: 'Location',
    multiLanguageKey: 'audience.competitor',
    dataFieldName: 'competitor',
    isSortable: true,
  },
  {
    displayName: 'Potential Revenue',
    multiLanguageKey: 'audience.table.potentialRevenue',
    dataFieldName: 'potentialRevenue',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'potentialRevenuePercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Competitor Processing Volume',
    multiLanguageKey: 'audience.table.competitorProcessingVolume',
    dataFieldName: 'competitorProcessingVolume',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'competitorProcessingPercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Competitor Merchants',
    multiLanguageKey: 'audience.table.competitorMerchants',
    dataFieldName: 'competitorMerchants',
    isSortable: true,
    fieldType: 'number',
  },
  {
    displayName: 'Revenue / Account',
    multiLanguageKey: 'audience.table.revenueAccount',
    dataFieldName: 'revenueAccount',
    isSortable: true,
    fieldType: 'currency',
  },
  {
    displayName: 'Market Share',
    multiLanguageKey: 'audience.table.competitormarketShare',
    dataFieldName: 'marketShare',
    isSortable: true,
    fieldType: 'percent',
  },
];
export const industryGridDefined = [
  { displayName: '', dataFieldName: 'checkbox' },
  {
    displayName: 'Location',
    multiLanguageKey: 'shared.common.industry',
    dataFieldName: 'industry',
    isSortable: true,
  },
  {
    displayName: 'Potential Revenue',
    multiLanguageKey: 'audience.table.potentialRevenue',
    dataFieldName: 'potentialRevenue',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'potentialRevenuePercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Competitor Processing Volume',
    multiLanguageKey: 'audience.table.competitorProcessingVolume',
    dataFieldName: 'competitorProcessingVolume',
    isSortable: true,
    hasPercentage: true,
    dataFieldNameForPercentage: 'competitorProcessingPercentage',
    fieldType: 'currency',
  },
  {
    displayName: 'Competitor Merchants',
    multiLanguageKey: 'audience.table.competitorMerchants',
    dataFieldName: 'competitorMerchants',
    isSortable: true,
    fieldType: 'number',
  },
  {
    displayName: 'Revenue / Account',
    multiLanguageKey: 'audience.table.revenueAccount',
    dataFieldName: 'revenueAccount',
    isSortable: true,
    fieldType: 'currency',
  },
  {
    displayName: 'Market Share',
    multiLanguageKey: 'audience.table.marketShare',
    dataFieldName: 'marketShare',
    isSortable: true,
    fieldType: 'percent',
  },
];

export const fieldNameAudienceFilter = [
  {
    displayName: 'Bank Officer',
    multiLanguageKey: 'audience.bank.officer',
    dataFieldName: 'branchOfficer',
  },
  {
    displayName: 'Branch',
    multiLanguageKey: 'shared.common.branch',
    dataFieldName: 'branch',
  },
  {
    displayName: 'Competitor',
    multiLanguageKey: 'audience.competitor',
    dataFieldName: 'competitor',
  },
  {
    displayName: 'Industry',
    multiLanguageKey: 'shared.common.industry',
    dataFieldName: 'industry',
  },
  {
    displayName: 'Location',
    multiLanguageKey: 'shared.common.text.location',
    dataFieldName: 'state',
  },
  {
    displayName: 'Merchant',
    multiLanguageKey: 'audience.merchant',
    dataFieldName: 'companyName',
  },
  {
    displayName: 'Processing Volume',
    multiLanguageKey: 'audience.processingVolmue',
    dataFieldName: 'competitorProcessing',
  },
];

export const pieChartOptions = {
  series: [0],
  dataLabels: [''],
  legend: {
    show: false,
    position: 'right',
  },
  chart: {
    type: 'pie',
    width: 300,
    stacked: false,
    toolbar: {
      show: false,
    },

    zoom: {
      enabled: true,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 300,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
};

export const mapStyle: object[] = [
  {
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }, { color: '#fcfcfc' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#bfd4ff' }],
  },
];

export const countryGeoLocation = [
  {
    country: 'US',
    lat: 40,
    lng: -97,
    zoom: 4,
  },
  {
    country: 'CA',
    lat: 64,
    lng: -100,
    zoom: 3,
  },
];

export const merchantSubheader = [
  'checkbox1',
  'Location',
  'potentialRevenue1',
  'competitorProcessingVolume1',
  'empty1',
  'empty2',
  'empty3',
  'empty4',
  'empty5',
  'empty6',
  'empty7',
];
export const branchSubheader = [
  'checkbox1',
  'Location',
  'potentialRevenue1',
  'competitorProcessingVolume1',
  'competitorMerchants1',
  'revenueAccount1',
  'marketShare1',
  'empty4',
];
export const competitorSubheader = [
  'checkbox1',
  'Location',
  'potentialRevenue1',
  'competitorProcessingVolume1',
  'competitorMerchants1',
  'revenueAccount1',
  'marketShare1',
  'empty4',
];
export const industrySubheader = [
  'checkbox1',
  'Location',
  'potentialRevenue1',
  'competitorProcessingVolume1',
  'competitorMerchants1',
  'revenueAccount1',
  'marketShare1',
  'empty4',
];

export const locationSubheader = [
  'checkbox1',
  'Location',
  'potentialRevenue1',
  'competitorProcessingVolume1',
  'competitorMerchants1',
  'revenueAccount1',
  'marketShare1',
  'empty4',
];

export const locationStatusSubheader = [
  'Location',
  'potentialRevenue1',
  'competitorProcessingVolume1',
  'competitorMerchants1',
  'revenueAccount1',
  'marketShare1',
  'empty4',
];

export const officerSubheader = [
  'checkbox1',
  'Location',
  'potentialRevenue1',
  'competitorProcessingVolume1',
  'competitorMerchants1',
  'revenueAccount1',
  'marketShare1',
  'empty4',
];

export const radialBarChartOptions = {
  series: [],
  chart: {
    type: 'radialBar',
    offsetY: 0,
    height: 350,
  },
  plotOptions: {
    radialBar: {
      startAngle: -110,
      endAngle: 110,
      hollow: {
        margin: 0,
        size: '78%',
        background: '#ffffff80',
        position: 'front',
        dropShadow: {
          enabled: false,
          top: 1,
          left: 0,
          blur: 2,
          opacity: 0.9,
        },
      },
      track: {
        background: '#e7e7e7',
        strokeWidth: '100%',
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          opacity: 0.31,
          blur: 2,
        },
      },
      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: '#2d841a',
          fontFamily: 'UniversForFiserv45',
          fontSize: '20px',
        },
        value: {
          formatter: (val: string) => `${val}%`,
          offsetY: 0,
          color: '#333',
          fontSize: '16px',
          fontFamily: 'UniversForFiserv45',
          show: true,
        },
      },
    },
  },
  fill: {
    colors: ['#2d841a'],
    type: 'solid',
  },
  labels: ['Potential Revenue'],
};

export const audienceListGridDefined = [
  {
    displayName: 'Segment Name',
    multiLanguageKey: 'audience.segmentName',
    dataFieldName: 'name',
    isSortable: true,
  },
  {
    displayName: 'Created By',
    multiLanguageKey: 'audience.createdBy',
    dataFieldName: 'createdBy',
    isSortable: true,
  },
  {
    displayName: 'Modified By',
    multiLanguageKey: 'audience.modifiedBy',
    dataFieldName: 'updatedBy',
    isSortable: true,
  },
  {
    displayName: 'Last Modified',
    multiLanguageKey: 'audience.updatedDate',
    dataFieldName: 'updatedDate',
    fieldType: 'date',
    isSortable: true,
  },
  {
    displayName: 'Total Merchants',
    multiLanguageKey: 'audience.totalMerchants',
    dataFieldName: 'totalMerchants',
    fieldType: 'number',
    isSortable: true,
  },
  {
    displayName: 'Approval Date',
    multiLanguageKey: 'audience.approvelDate',
    dataFieldName: 'approvalDate',
    fieldType: 'date',
    isSortable: true,
  },
  {
    displayName: 'Action',
    multiLanguageKey: 'audience.action',
    dataFieldName: 'Action',
  },
];

export const audienceActions = {
  save: { show: false, enable: false },
  submit: { show: false, enable: false },
  approve: { show: false, enable: true },
  decline: { show: false, enable: true },
};

export const audienceDetails = {
  name: '',
  description: '',
};

export const audienceSummary = {
  activeCampaignMembers: 0,
  applicationResponse: '',
  averageRevenueAccount: 0,
  competitors: [],
  errorCode: '0',
  message: '',
  totalMerchants: 0,
  totalPotentialRevenue: 0,
};

export const audienceDefaultPayload = {
  page: 0,
  recordsPerPage: pageSizeOptions[0],
  sortBy: 'potentialRevenue',
  sortOrder: 'DESC',
  filters: {},
};

export const AudienceOverviewData = {
  activeBranches: '0',
  competitorMerchants: '0',
  potentialRevenue: '0',
  revenuePotential: '0',
};

export const audienceDetailViewStaticContent = {
  dowloadApiSuccess: 'audience.msg.download.success',
  dowloadApiFailed: 'audience.msg.download.failed',
  somethingApiError: 'something.wrong',
  successApproved: 'audience.msg.suceess.approved',
  successFailed: 'audience.msg.suceess.failed',
};

export const audienceEditStaticContent = {
  successSubmit: 'audience.msg.suceess.submitted',
  somethingApiError: 'something.wrong',
  successApproved: 'audience.msg.suceess.approved',
  successFailed: 'audience.msg.suceess.failed',
};
export const audienceStaticContent = {
  dowloadApiSuccess: 'audience.msg.download.success',
  dowloadApiFailed: 'audience.msg.download.failed',
  somethingApiError: 'something.wrong',
};
export const audiencePrimaryViewStaticContent = {
  successSaveSubmit: 'audience.msg.suceess.saved',
  somethingApiError: 'something.wrong',
};
