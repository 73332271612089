import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { pageSizeOptions } from '@app/appConstants';
import { showingList } from '@app/features/leads/leadConstants';
import { FilterUpdate } from '@app/model/interfaces/common';
import { RefineSearchTagClickedItem } from '@app/model/interfaces/menu';
import { LanguageService } from '@app/shared/services/language.service';
import { getUTCbyDate, replaceStringVal } from '@app/shared/utils/functions';
import * as moment from 'moment';

@Component({
  selector: 'clover-refine-search',
  templateUrl: './refine-search.component.html',
  styleUrls: ['./refine-search.component.scss'],
})
export class RefineSearchComponent implements OnInit {
  selectedFilterColumnData = 2;
  showingList = showingList;
  selfLead!: boolean;
  apiVersion = 'v2';
  unqualified!: boolean;
  doNotContact!: boolean;
  selectedFilterCount = 0;
  @ViewChild('customDatePicker') customDatePicker!: MatDatepicker<Date>;
  customDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  today = new Date();
  pageSize: number =
    Number(sessionStorage.getItem('previousIndex')) || pageSizeOptions[0];
  sortBy = 'dateCreated';
  sortOrder = 'DESC';
  gridViewFilter = new Map<string, string>();
  predefinedTags!: RefineSearchTagClickedItem[];

  refineSearchStaticContent = {
    searchingFor: 'refineSearch.searchingFor',
  };

  staticLocaleContent: {
    [key: string]: string;
  } = this.language.getLocaleString(this.refineSearchStaticContent);

  getSearchHeading: string = replaceStringVal(
    this.staticLocaleContent.searchingFor,
    `"${this.searchParams.searchTxt}"`,
    '<searchText>'
  );

  constructor(
    private language: LanguageService,
    public fullDialogRef: MatDialogRef<RefineSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public searchParams: any
  ) {}

  ngOnInit(): void {
    const { payload, selectedFilterColumnData, predefinedTags } =
      this.searchParams;
    if (this.searchParams.selectedFilterColumnData === 4) {
      this.customDate.patchValue({
        start: moment(this.searchParams.dateFilter[0].value).format(
          'YYYY-MM-DD'
        ),
        end: moment(this.searchParams.dateFilter[1].value)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
      });
    }
    this.selfLead = payload.selfOnly;
    this.unqualified = payload.unqualified;
    this.doNotContact = payload.doNotContact;
    this.selectedFilterColumnData = selectedFilterColumnData;
    this.selectedFilterCount = this.getSelectedPreTags(predefinedTags);
  }

  selfLeadCheck = (event: MatSlideToggleChange) => {
    this.searchParams.payload.selfOnly = event.checked;
  };

  unqualifiedCheck = (event: MatSlideToggleChange) => {
    this.searchParams.payload.unqualified = event.checked;
  };

  doNotContactCheck = (event: MatSlideToggleChange) => {
    this.searchParams.payload.doNotContact = event.checked;
  };

  showingFilterUpdated(item: FilterUpdate): void {
    if (item.id == 4) {
      this.customDatePicker.open();
    }
  }
  getSelectedPreTags = (arr: RefineSearchTagClickedItem[]) =>
    arr.filter((item: RefineSearchTagClickedItem) => item.selected).length;

  toggleItemSearchIn = (clickedItem: RefineSearchTagClickedItem) => {
    const searchTxt = this.searchParams.searchTxt;
    const { predefinedTags } = this.searchParams;
    predefinedTags.forEach((item: RefineSearchTagClickedItem) => {
      if (item.dataFieldName === clickedItem.dataFieldName) {
        item.selected = !item.selected;
        if (item.selected) {
          item.value = searchTxt;
          this.selectedFilterCount = this.selectedFilterCount + 1;
        } else {
          item.value = '';
          this.selectedFilterCount = this.selectedFilterCount - 1;
        }
      }
    });
  };
  getPayloadObject = (
    direction: string,
    fieldName: string,
    condition: string,
    value: string
  ) => {
    return {
      direction,
      fieldName,
      condition,
      value,
      options: [],
    };
  };

  narrowResultsParams = (closeIconClicked = true) => {
    const filterVal: Map<string, string> = new Map();
    const datefilters: any = [];
    const filters: { combinator: string; expressions: any } = {
      combinator: 'OR',
      expressions: [],
    };
    let dateObj = {};
    let dateObj1 = {};
    if (
      this.selectedFilterColumnData === 1 ||
      this.selectedFilterColumnData === 2
    ) {
      const last30thDate = moment(
        new Date().setDate(this.today.getDate() - 29)
      ).format('YYYY-MM-DD');
      const last60thDate = moment(
        new Date().setDate(this.today.getDate() - 59)
      ).format('YYYY-MM-DD');
      dateObj = this.getPayloadObject(
        'INCLUDES',
        'dateCreated',
        'GREATERTHANEQUAL',
        this.selectedFilterColumnData === 1 ? getUTCbyDate(last30thDate) : getUTCbyDate(last60thDate)
      );
    } else if (this.selectedFilterColumnData === 4) {
      const startDate = moment(
        this.customDate.value.start || moment(new Date())
      ).format('YYYY-MM-DD');
      const endDate = moment(
        this.customDate.value.end ||
          this.customDate.value.start ||
          moment(new Date())
      ).format('YYYY-MM-DD');
      dateObj = this.getPayloadObject(
        'INCLUDES',
        'dateCreated',
        'GREATERTHANEQUAL',
        getUTCbyDate(startDate)
      );
      dateObj1 = this.getPayloadObject(
        'INCLUDES',
        'dateCreated',
        'LESSTHANEQUAL',
        getUTCbyDate(moment(endDate).add(1, 'days').format('YYYY-MM-DD'))
      );
    } else {
      const currentDate = moment(
        new Date().setDate(this.today.getDate() + 1)
      ).format('YYYY-MM-DD');
      dateObj = this.getPayloadObject(
        'INCLUDES',
        'dateCreated',
        'LESSTHANEQUAL',
        getUTCbyDate(moment(currentDate).add(1, 'days').format('YYYY-MM-DD'))
      );
    }
    if (this.selectedFilterColumnData === 4) {
      datefilters.push(dateObj);
      datefilters.push(dateObj1);
    } else if (
      this.selectedFilterColumnData === 1 ||
      this.selectedFilterColumnData === 2
    ) {
      datefilters.push(dateObj);
    }

    if (!closeIconClicked) {
      const { predefinedTags, payload } = this.searchParams;
      predefinedTags.forEach((item: RefineSearchTagClickedItem) => {
        if (item.selected) {
          filterVal.set(item.dataFieldName, item.value);
          const obj = this.getPayloadObject(
            'INCLUDES',
            item.dataFieldName,
            'CONTAINS',
            item.value
          );
          filters.expressions.push(obj);
        }
      });

      this.searchParams.apiVersion =
        payload.selfOnly ||
        payload.unqualified ||
        this.selectedFilterCount < 5
          ? 'v3'
          : 'v2';
      this.searchParams.dateFilter = [...datefilters];
      this.searchParams.payload.filters = { ...filters };
      this.searchParams.selectedFilterColumnData =
        this.selectedFilterColumnData;
      this.fullDialogRef.close({ data: this.searchParams, success: true });
    } else {
      this.fullDialogRef.close();
    }
  };
}
