<div class="m-4">
  <div class="d-flex mb-3 justify-content-between">
    <h2 class="section-title">
      {{ 'refineSearch' | translate }}
    </h2>
    <button class="btn btn-icon" (click)="narrowResultsParams()">
      <img src="assets/images/icons/icon_dialog_close.svg" />
    </button>
  </div>
  <p class="mb-4">
    <mat-slide-toggle [checked]="selfLead" (change)="selfLeadCheck($event)">
      {{ 'refineSearch.selfLead' | translate }}
    </mat-slide-toggle>
  </p>
  <p *ngIf="!searchParams.displayReferralSearch">
    <mat-slide-toggle
      [checked]="unqualified"
      (change)="unqualifiedCheck($event)"
    >
      {{
        (searchParams.hasAccountsMenu
          ? 'refineSearch.unqualified'
          : 'refineSearch.unqualifiedLeadAndOppo'
        ) | translate
      }}
    </mat-slide-toggle>
  </p>
  <p *ngIf="searchParams.isMoaAdmin">
    <mat-slide-toggle
      [checked]="doNotContact"
      (change)="doNotContactCheck($event)"
    >
      {{ 'refineSearch.donotContact' | translate }}
    </mat-slide-toggle>
  </p>
  <div class="col-md-12 mb-4">
    <mat-form-field class="cloverInput" appearance="fill">
      <mat-label>
        <span *ngIf="searchParams.hasMoaPriviledge"
          >{{ 'moa.list.created' | translate }}
          <span *ngIf="searchParams.isMoaAdmin"
            >, {{ 'prospects.assigned' | translate }}</span
          ></span
        >
        <span *ngIf="!searchParams.displayReferralSearch">
          <span *ngIf="searchParams.hasMoaPriviledge">, </span>
          {{ 'leads.created' | translate }},
          {{ 'opportunities.qualified' | translate }}</span
        >
      </mat-label>
      <mat-select [(ngModel)]="selectedFilterColumnData">
        <mat-option
          *ngFor="let showing of showingList"
          [value]="showing.id"
          (click)="showingFilterUpdated(showing)"
          >{{ showing.multiLanguageKey | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field class="hideDatePicketField" appearance="fill">
      <mat-label>{{
        'lead.view.grid.filter.enter.date.range' | translate
      }}</mat-label>
      <mat-date-range-input
        [formGroup]="customDate"
        [rangePicker]="customDatePicker"
      >
        <input
          matStartDate
          formControlName="start"
          placeholder="Start date"
          autocomplete="disable"
        />
        <input
          matEndDate
          formControlName="end"
          placeholder="End date"
          autocomplete="disable"
        />
      </mat-date-range-input>
      <mat-date-range-picker #customDatePicker>
        <mat-date-range-picker-actions>
          <button class="edit-btn" matDateRangePickerCancel>
            {{ 'common.button.text.cancel' | translate }}
          </button>
          <button matDateRangePickerApply>
            {{ 'shared.apply' | translate }}
          </button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="">
    <h3 class="primary-color-1 fs-18">
      {{ getSearchHeading }}
    </h3>
    <p>
      {{
        (selectedFilterCount === searchParams.predefinedTags.length
          ? 'refineSearch.allColumnAdded'
          : selectedFilterCount > 0 &&
            selectedFilterCount < searchParams.predefinedTags.length
          ? 'refineSearch.someColumnAdded'
          : 'refineSearch.zeroColumnAdded'
        ) | translate
      }}
    </p>
  </div>
  <div class="mb-4">
    <div
      *ngFor="let item of searchParams.predefinedTags"
      class="mb-2 p-2 preDefinedTags"
      [class.selected]="item.selected"
      title="{{ item.multiLanguageKey | translate }}"
      (click)="toggleItemSearchIn(item)"
    >
      <span>{{ item.selected ? '-' : '+' }}</span>
      {{ item.multiLanguageKey | translate }}
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button
      [disabled]="!(selectedFilterCount > 0)"
      [class.disabled]="!(selectedFilterCount > 0)"
      (click)="narrowResultsParams(false)"
    >
      {{ 'refineSearch.narrowResults' | translate }}
    </button>
  </div>
</div>
