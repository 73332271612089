import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  appStaticContent,
  mobileMenuList,
  NonMoAENabled,
  productionUrl,
} from '@app/appConstants';
import {
  Bank,
  FeatureModuleVersion,
  MenuItem,
  MicrosoftURLResponse,
  PreferenceJsonResponse,
  UserData,
  UserInfo,
} from '@app/model/interfaces/menu';
import { UserDetailsResponse } from '@app/model/interfaces/settings';
import { MicrosoftAuthComponent } from '@app/shared/component/microsoft-auth/microsoft-auth.component';
import { LanguageService } from '@app/shared/services/language.service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { SharedService } from '@app/shared/shared.service';
import { DataSharingService } from '@shared/services/data-sharing.service';
import { HeaderService } from '@shared/services/header.service';
import { ToasterService } from '@shared/services/toaster.service';
import { UserInfoService } from '@shared/services/user-info.service';
import { interval } from 'rxjs';
import { ConfirmComponent } from '@app/shared/confirm/confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { RealTimeEventService } from '@app/shared/services/real-time-event.service';
import { SettingsService } from '@app/features/settings/settings.service';
import { WebsocketsService } from '@app/websockets.service';
import { RolesResponse } from '@app/model/interfaces/user-management';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'clover-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isUserInfoLoaded = false;
  microsoftUrl: string = this.userInfoService.getMicrosoftURLPath();
  checkDesktopDevice = true;
  showBcPersonaFeatures = false;
  isExpiredPopupAvailable = false;
  isTranslationLoaded = false;
  staticContent!: { [key: string]: string };
  expirePopUpRef!: MatDialogRef<any>;
  userData = { redirectUrl: '', userid: '' };
  window: any = window;
  domainType = productionUrl.includes(window.location.origin) ? 'prod' : 'qa';
  constructor(
    private headerService: HeaderService,
    private userInfoService: UserInfoService,
    private toasterService: ToasterService,
    private dataSharing: DataSharingService,
    private router: Router,
    private language: LanguageService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private sharedService: SharedService,
    private realTimeEventService: RealTimeEventService,
    private settingsService: SettingsService,
    public dialog: MatDialog,
    private webSocket: WebsocketsService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.addFiservFont();
    this.language.languageChanged.subscribe({
      next: (res: string) => {
        if (res) {
          this.translate.setDefaultLang(res);
        }
      },
    });
    this.webSocket.attemptToConnectEvent.subscribe({
      next: () => {
        this.realTimeEventService.webSocketMessage(this.userData.userid);
      },
    });
    if (
      !sessionStorage.getItem('pathName') &&
      window.location.pathname !== '/'
    ) {
      sessionStorage.setItem('pathName', window.location.pathname);
    }
    this.checkTranslationLoaded();
    this.checkUserDevice();
    this.headerService.getUserData().subscribe((data: UserData) => {
      this.realTimeEventService.webSocketMessage(data.userid);
      this.getAppVersions();
      this.getAppPreferences();
      this.userData = { ...data };
      this.userInfoService.setUserAuthInfo(data);
    });
  }

  addFiservFont() {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      @font-face {
        font-family: 'UniversForFiserv45';
        src: url('${environment.cdnUniversForFiserv45}') format('truetype');
      }
      @font-face {
        font-family: 'UniversForFiserv55';
        src: url('${environment.cdnUniversForFiserv55}') format('truetype');
      } 
    `;
    this.renderer.appendChild(document.head, style);
  }


  checkTranslationLoaded = () => {
    this.translate.get('shared.confirm').subscribe(
      (text: string) => {
        if (text === 'shared.confirm') {
          setTimeout(() => {
            this.checkTranslationLoaded();
          }, 1000);
        } else {
          this.staticContent = this.language.getLocaleString(appStaticContent);
        }
      },
      () => {
        setTimeout(() => {
          this.checkTranslationLoaded();
        }, 1000);
      }
    );
  };

  getAppVersions = (): void => {
    this.userInfoService
      .getModuleFeatures()
      .subscribe((res: FeatureModuleVersion) => {
        this.getUserDetails();
        this.getUserRoles();
        this.showBcPersonaFeatures = res.ShowBcPersonaFeatures;
        this.userInfoService.setAvailableModuleFeatures(res);
        if (res['Settings.PersonalizedTexts']) {
          this.getTags();
        }
      });
  };

  getUserRoles = () => {
    this.settingsService.getRoles().subscribe((data: RolesResponse) => {
      if (data.errorCode === '0') {
        this.settingsService.setUserRoles(data.roles);
        this.settingsService.setUserPrivilege([
          ...(data.userPrivileges || []),
          NonMoAENabled,
        ]);
      }
    });
  };

  getAppPreferences = () => {
    this.userInfoService.getAppPreferences().subscribe(
      (res: PreferenceJsonResponse) => {
        if (res.statusCode === 200) {
          this.userInfoService.setUserPreferences(res.data);
        } else {
          const errorMessage = res.message
            ? res.message
            : this.staticContent.preferenceFetchError;
          this.toasterService.error(errorMessage);
        }
      },
      (err) => this.toasterService.error(err)
    );
  };

  getUserDetails = () => {
    this.sharedService
      .getUserDetails()
      .subscribe((data: UserDetailsResponse) => {
        const { userDetails } = data;
        document.cookie = `catalystUserIdHash=${userDetails.userIdHash}`;
        document.cookie = `catalystUserId=${userDetails.userId}`;
        this.sharedService.setProfileDetails(userDetails);
        this.getUserAccess();
      });
  };

  showConsentPopup = () => {
    let showPopup = false;
    if (this.isTranslationLoaded && this.isUserInfoLoaded) {
      const data = this.userInfoService.getUserInfo();
      if (data.telecomConsentNeeded) {
        showPopup = true;
      }
    }
    if (!showPopup) {
      return;
    }
    const userProfileDetails = { ...this.sharedService.getProfileDetails() };
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '600px',
      disableClose: true,
      data: {
        title: this.staticContent.mobilePhoneConsentHeading,
        message: this.staticContent.mobilePhoneConsentMessage,
        cancelButton: this.staticContent.mobilePhoneButtonRevoke,
        confirmButton: this.staticContent.mobilePhoneButtonConsent,
        mobileConsent: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      userProfileDetails.telecomConsent = result === 'success';
      userProfileDetails.phone =
        result === 'success' ? userProfileDetails.phone : '';
      this.sharedService.setProfileDetails(userProfileDetails);
      this.settingsService.updateUserDetails(userProfileDetails).subscribe(
        (data: UserDetailsResponse) => {},
        (err) => {
          this.toasterService.error(this.staticContent.somethingApiError);
        }
      );
    });
  };
  getSelectedLanguage = () => {
    this.localStorageService.setItem(
      'getSelectedLanguage',
      this.userInfoService.getSelectedLanguage()
    );
    this.language.setLanguage(this.userInfoService.getSelectedLanguage());
  };

  getTags = (): void => {
    this.sharedService.getPersonalText().subscribe(
      (res) => {
        if (res.statusCode === 200) {
          this.sharedService.setTagNames(res.data);
        }
      },
      (err) => this.toasterService.error(err)
    );
  };

  checkUserDevice = () => {
    this.userInfoService.setUserDevice(
      /iphone|ipad|android|ie|blackberry|fennec/.test(
        navigator.userAgent.toLowerCase()
      )
    );
  };

  checkUserActive = () => {
    interval(60000).subscribe(() => {
      this.userInfoService.checkUserActive().subscribe((res) => {
        if (res.expired) {
          this.userInfoService.logout().subscribe(
            () => (window.location.href = this.userData.redirectUrl),
            () => (window.location.href = this.userData.redirectUrl)
          );
        }
        if (res.expiresIn < 300000) {
          if (!this.isExpiredPopupAvailable) {
            this.showExpirePopup();
            this.isExpiredPopupAvailable = true;
          }
        } else {
          this.isExpiredPopupAvailable = false;
          this.expirePopUpRef?.close();
        }
      });
    });
  };

  showExpirePopup = () => {
    this.expirePopUpRef = this.dialog.open(ConfirmComponent, {
      width: '600px',
      disableClose: true,
      data: {
        title: this.staticContent.expirePopupTitle,
        message: this.staticContent.expirePopupBody,
        cancelButton: this.staticContent.cancel,
        confirmButton: this.staticContent.expirePopupButton,
      },
    });
    this.expirePopUpRef.afterClosed().subscribe((result: string) => {
      if (result === 'success') {
        this.sharedService
          .getUserDetails()
          .subscribe((res) =>
            this.sharedService.setProfileDetails(res.userDetails)
          );
      } else {
        const userAuthInfo = this.userInfoService.getUserAuthInfo();
        this.userInfoService.logout().subscribe(
          () => (window.location.href = userAuthInfo.redirectUrl),
          () => (window.location.href = userAuthInfo.redirectUrl)
        );
      }
    });
  };

  getUserAccess(): void {
    this.headerService.getUserAccess().subscribe(
      (data: UserInfo) => {
        this.userInfoService.setUserInfo(data);
        this.isUserInfoLoaded = true;
        if (data.telecomConsentNeeded) {
          this.showConsentPopup();
        }
        this.getSelectedLanguage();
        this.dataSharing.bankListChanged.next(data);
        let pathName = '';
        if (!this.userInfoService.getUserDevice()) {
          const menus: MenuItem[] = this.userInfoService.getMenu();
          const mobileMenu = mobileMenuList;
          mobileMenu.forEach((value) => {
            const menuItem = menus.find(
              (obj: MenuItem) => obj.name === value.name && obj.access === true
            );
            if (pathName == '' && menuItem?.access) {
              pathName = value.url;
            }
          });
        } else {
          pathName = '/overview';
        }
        pathName = sessionStorage.getItem('pathName') || pathName;
        sessionStorage.removeItem('pathName');
        this.router.navigate([pathName]);
        this.checkUserActive();
        this.checkAudienceAvailability();
        if (this.showBcPersonaFeatures && !data.msalAccessTokenPresent) {
          this.userInfoService.getMicrosoftURL().subscribe(
            (res: MicrosoftURLResponse) => {
              if (res.statusCode === 200) {
                this.microsoftUrl = res.data.msalUrl;
                this.userInfoService.setMicrosoftURLPath(res.data.msalUrl);
                if (!data.isMsalAccessDeclined) {
                  this.openMicrosoftPopup();
                }
              }
            },
            (err: any) => this.toasterService.error(err)
          );

          const { sessionTrackingList } =
            this.userInfoService.getUserAuthInfo();
          if (sessionTrackingList && sessionTrackingList.length) {
            const createdAt =
              sessionTrackingList[sessionTrackingList.length - 1].createdAt;
            if (sessionTrackingList.length === 1) {
              this.localStorageService.setItem('createdAt', String(createdAt));
              return;
            }
            const createdAtFromLS =
              this.localStorageService.getItem('createdAt');
            let loggedInNow = true;
            sessionTrackingList.forEach((sessionTrackingList: any) => {
              if (String(sessionTrackingList.createdAt) === createdAtFromLS) {
                loggedInNow = false;
              }
            });
            if (loggedInNow) {
              this.localStorageService.setItem('createdAt', String(createdAt));
              this.concurrentExistingSessionPopup(sessionTrackingList);
            }
          }
        }
      },
      (error) => {
        this.toasterService.error('Server is down or User access denied');
      }
    );
  }

  openMicrosoftPopup = () => {
    this.dialog.open(MicrosoftAuthComponent, {
      width: '500px',
      height: '250px',
    });
  };
  checkAudienceAvailability = () => {
    const userId = this.userInfoService.getUserId();
    const storedBank: Bank =
      JSON.parse(this.localStorageService.getItem(`selectedBank_${userId}`)) ||
      this.userInfoService.getBanks()[0];
    const globalMenus: MenuItem[] = this.userInfoService.getMenu();
    let bankInfo: Bank = this.userInfoService
      .getBanks()
      .filter((bank: Bank) => bank.id === storedBank.id)[0];
    bankInfo = bankInfo ? bankInfo : this.userInfoService.getBanks()[0];
    const isAudienceEnabled: boolean =
      storedBank.id === bankInfo.id
        ? storedBank.audienceEnabled
          ? storedBank.audienceEnabled
          : bankInfo.audienceEnabled
        : bankInfo.audienceEnabled;
    globalMenus.map((element: MenuItem) => {
      if (element.name === 'audience') {
        element.access = element.access && isAudienceEnabled;
      }
    });
    this.userInfoService.setMenu(globalMenus);
  };

  concurrentExistingSessionPopup = (data: any) => {
    const concurrentData = {
      type: 'conCurrentExistingSession',
      data,
    };
    setTimeout(() => {
      this.headerService.newNotificationReceived(concurrentData);
    }, 1000);
  };
}