import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  Notification,
  NotificationResponse,
} from '@app/model/interfaces/notification';
import { HeaderService } from '@app/shared/services/header.service';
import { LanguageService } from '@app/shared/services/language.service';
import { ToasterService } from '@app/shared/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { Bank } from '@app/model/interfaces/menu';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { DataSharingService } from '@app/shared/services/data-sharing.service';
import { UserInfoService } from '@app/shared/services/user-info.service';
import { Subscription } from 'rxjs';
import { AgendaService } from '../agenda/agenda.service';
import { AudienceService } from '../audience/audience.service';
import { BankInfoService } from '@app/shared/services/bank-info.service';
import { AudienceActionResponse } from '@app/model/interfaces/audience';
import { errorMessage } from '../audience/audienceConstants';
import { UserDetailsResponse } from '@app/model/interfaces/settings';
import * as moment from 'moment';
import { SettingsService } from '../settings/settings.service';
import { getEncodingURL } from '@app/shared/utils/functions';
import { SharedService } from '@app/shared/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { 
  riskAlertsNotificationTypes,
  riskAlertsHeadingContent,
  riskAlertsBatchHeadingContent
 } from '../my-accounts/riskalertsConstant';
 import { RiskAlertsBatchViewDetailsComponent } from '../my-accounts/risk-alerts-batch-view-details/risk-alerts-batch-view-details.component';

@Component({
  selector: 'clover-all-notifications-dialog',
  templateUrl: './all-notifications-dialog.component.html',
  styleUrls: ['./all-notifications-dialog.component.scss'],
})
export class AllNotificationsDialogComponent {
  selectedIndex = 0;
  @Input() notificationDownLaodList: Notification[] = [];
  @Input() isPopup = false;
  @Input() totalCount: number | undefined;
  @Input() notificationResultList: Notification[] = [];
  @Input() notificationActionList: Notification[] = [];
  @Input() moaList: Notification[] = [];
  @Input() nonMoaList: Notification[] = [];
  @Input() moaListCount: Number = 0;
  @Input() hasMoaPriviledge: boolean = Boolean(
    this.sharedService.getProfileDetails().moaPrivilege
  );
  currentTab!: string;
  viewMoreClicked = false;
  isopenNotifiaction = false;
  notificationStaticContent = {
    moaProspects: 'notification.tab.moaProspects',
    salesLeasOpps: 'notification.tab.salesLead&Opps',
    referrals: 'referral.activity.chart.referrals',
    notificationClear: 'notification.msg.success.clear',
    serverError: 'notification.msg.server.error',
  };
  maxNotificationId = 0;
  showNotification = false;
  showLoader = false;
  subscriptionAudienceActionData!: Subscription;
  staticContent = this.language.getLocaleString(this.notificationStaticContent);
  currentUserDetails = this.sharedService.getProfileDetails();
  activeTabs = [
    {
      tab: this.staticContent.salesLeasOpps,
      value: 'saleslead',
      count: 0,
    },
  ];

  constructor(
    private translate: TranslateService,
    private language: LanguageService,
    private headerService: HeaderService,
    private settingService: SettingsService,
    private router: Router,
    private toasterService: ToasterService,
    private localStorageService: LocalStorageService,
    private dataSharingService: DataSharingService,
    private userInfoService: UserInfoService,
    private agendaService: AgendaService,
    private audienceService: AudienceService,
    private bankInfoService: BankInfoService,
    private sharedService: SharedService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (
      this.currentUserDetails.role === '6' ||
      this.currentUserDetails.role === '7'
    ) {
      this.activeTabs[0].tab = this.staticContent.salesLeasOpps;
      this.activeTabs[0].value = 'saleslead';
    } else {
      this.activeTabs[0].tab = this.staticContent.referrals;
      this.activeTabs[0].value = 'referral';
    }
    this.language.languageChanged.subscribe({
      next: (res: string) => {
        this.translate.setDefaultLang(res);
      },
      error: (error) => {},
      complete: () => {},
    });
    if (this.hasMoaPriviledge) {
      this.activeTabs.unshift({
        tab: this.staticContent.moaProspects,
        value: 'moaprospect',
        count: 0,
      });
    }
    this.viewMoreClicked = false;
    if (!this.isPopup) {
      this.showLoader = true;
      this.dataSharingService.bellNotificationEvent.subscribe({
        next: () => {
          this.getLatestNotifications();
        },
      });
    } else {
      this.loadUserDetails();
    }
    if (!this.isPopup) {
      this.checkNotificationCenterAvaialbility();
    }
  }

  onTabChanged = (tab: string) => {
    this.currentTab = tab;
  };
  getNotificationSettings() {
    this.router.navigate(['settings/notifications']);
  }

  clearNotification(): void {
    const notificationsId: number[] = [];
    this.notificationResultList.forEach((val: Notification) =>
      notificationsId.push(val.id)
    );
    const payLoad = { notificationsId };
    this.headerService.clearAllNotification(payLoad).subscribe(
      (data: NotificationResponse) => {
        if (data.errorCode === '0') {
          this.notificationResultList = [];
          this.notificationActionList = [];
          this.notificationDownLaodList = [];
          this.moaList = [];
          this.nonMoaList = [];
          this.moaListCount = 0;
          this.isopenNotifiaction = false;
          this.toasterService.success(this.staticContent.notificationClear);

          this.dataSharingService.bellNotificationEvent.next(null);
        }
      },
      (error) => {
        this.toasterService.error(this.staticContent.serverError);
      }
    );
  }
  checkNotificationCenterAvaialbility = () => {
    this.settingService.getNotifications().subscribe((data: any) => {
      this.showNotification = Boolean(data.notificationGroups.length);
    });
  };

  getLatestNotifications(): void {
    const userId = this.userInfoService.getUserId();
    this.headerService
      .getLatestUserNotificationCount(userId, this.maxNotificationId)
      .subscribe((data: NotificationResponse) => {
        if (data.errorCode === '0') {
          if (this.maxNotificationId !== data.maxNotificationId) {
            this.maxNotificationId = data.maxNotificationId;
            this.loadNotifications();
          } else {
            this.showLoader = false;
          }
        }
      });
  }

  loadNotifications(): void {
    const userId = this.userInfoService.getUserId();
    this.headerService
      .getUserNotification(userId)
      .subscribe((data: NotificationResponse) => {
        this.showLoader = false;
        if (data.notifications) {
          this.notificationResultList = data.notifications;
          this.moaList = this.notificationResultList.filter(
            (val) => val.notificationGroupName === 'Prospect'
          );
          this.moaListCount = this.moaList.length;
          this.nonMoaList = this.notificationResultList.filter(
            (val) => val.notificationGroupName !== 'Prospect'
          );

          this.notificationActionList = this.nonMoaList.filter(
            (val) => val.notificationGroupName !== 'DownloadGroup'
          );
          this.notificationDownLaodList = this.nonMoaList.filter(
            (val) => val.notificationGroupName === 'DownloadGroup'
          );

          this.setDataAndSort();
          this.notificationActionList = this.isPopup
            ? this.notificationActionList.slice(0, 3)
            : this.notificationActionList;
          this.notificationDownLaodList = this.isPopup
            ? this.notificationDownLaodList.slice(0, 3)
            : this.notificationDownLaodList;
          this.loadUserDetails();
          this.autoDownloadNotifications();
        }
      });
  }

  getEncodingURL = (imagePath: string | undefined) => getEncodingURL(imagePath);

  setDataAndSort(): void {
    this.moaList = this.moaList.sort(
      (a: Notification, b: Notification) =>
        Date.parse(b.createdDate) - Date.parse(a.createdDate)
    );
    this.notificationActionList = this.notificationActionList.sort(
      (a: Notification, b: Notification) =>
        Date.parse(b.createdDate) - Date.parse(a.createdDate)
    );
    this.notificationDownLaodList = this.notificationDownLaodList.sort(
      (a: Notification, b: Notification) =>
        Date.parse(b.createdDate) - Date.parse(a.createdDate)
    );
  }

  setNotificationBody(
    item: Notification,
    ressultMap: Map<string, Notification[]>
  ): void {
    if (item.notificationBody) {
      try {
        item.notificationBodyParsed = JSON.parse(item.notificationBody);
      } catch {
        item.notificationBodyParsed = undefined;
      }
      if (
        item.notificationBodyParsed &&
        item.notificationBodyParsed.sourceUserId
      ) {
        const userDetails = this.dataSharingService.getUserInfoMap(
          item.notificationBodyParsed.sourceUserId.toLowerCase()
        );
        if (userDetails) {
          item.userName = userDetails.firstName + ' ' + userDetails.lastName;
          item.notificationBodyParsed.textTemplate =
            item.notificationBodyParsed.textTemplate.replace(
              '{username}',
              item.userName
            );
          item.profileImage = userDetails.imageUrl;
          item.hasImage = item.profileImage ? true : false;
        } else {
          const key = item.notificationBodyParsed.sourceUserId.toLowerCase();
          if (ressultMap.has(key)) {
            ressultMap.get(key)?.push(item);
          } else {
            const arr = [];
            arr.push(item);
            ressultMap.set(key, arr);
          }
        }
      }
    }
  }
  onClickViewAll(): void {
    this.router.navigate([`/notifications`]);
  }

  loadUserDetails(): void {
    const ressultMap: Map<string, Notification[]> = new Map();
    this.notificationActionList.forEach((item: Notification) => {
      item.formattedDate = moment.utc(item.createdDate).fromNow();
      this.setNotificationBody(item, ressultMap);
    });
    const moaMap: Map<string, Notification[]> = new Map();
    this.moaList.forEach((item: Notification) => {
      item.formattedDate = moment.utc(item.createdDate).fromNow();
      this.setNotificationBody(item, moaMap);
    });
    const ressultMapDownLoad: Map<string, Notification[]> = new Map();
    this.notificationDownLaodList.forEach((item: Notification) => {
      item.formattedDate = moment.utc(item.createdDate).fromNow();
      this.setNotificationBody(item, ressultMap);
    });
    for (const keyVal of ressultMap.keys()) {
      this.settingService
        .getUserDetail(keyVal)
        .subscribe((user: UserDetailsResponse) => {
          if (user.userDetails) {
            ressultMap
              .get(user.userDetails.userId.toLowerCase())
              ?.forEach((itemVal) => {
                this.dataSharingService.setUserInfoMap(
                  user.userDetails.userId.toLowerCase(),
                  user.userDetails
                );
                itemVal.userName =
                  user.userDetails.firstName + ' ' + user.userDetails.lastName;
                itemVal.profileImage = user.userDetails.imageUrl;
                if (itemVal.notificationBodyParsed) {
                  itemVal.notificationBodyParsed.textTemplate =
                    itemVal.notificationBodyParsed.textTemplate.replace(
                      '{username}',
                      itemVal.userName
                    );
                }
                itemVal.hasImage = itemVal.profileImage ? true : false;
              });
          } else {
            ressultMap.get(keyVal)?.forEach((itemVal) => {
              if (itemVal.notificationBodyParsed) {
                itemVal.notificationBodyParsed.textTemplate =
                  itemVal.notificationBodyParsed.textTemplate.replace(
                    '{username}',
                    keyVal
                  );
              }
            });
          }
        });
    }
  }

  onClickViewMore(): void {
    this.viewMoreClicked = true;
    this.notificationActionList = this.notificationResultList.filter(
      (val) => val.notificationGroupName !== 'DownloadGroup'
    );
    this.notificationDownLaodList = this.notificationResultList.filter(
      (val) => val.notificationGroupName === 'DownloadGroup'
    );
    this.setDataAndSort();
    this.loadUserDetails();
  }

  openNotification(): void {
    this.isopenNotifiaction = !this.isopenNotifiaction;
  }

  readNotification(item: Notification, isAutoDownload: boolean): void {
    const notificationsId: number[] = [];
    notificationsId.push(item.id);
    const payLoad = { notificationsId };
    this.headerService
      .readNotification(payLoad)
      .subscribe((data: NotificationResponse) => {
        if (data.errorCode === '0') {
          item.isVisited = true;
          if (item.notificationBodyParsed && !isAutoDownload) {
            if (item.notificationBodyParsed.entityType === 'prospectList') {
              this.openNotification();
              this.reloadRouter(
                `/moa/yourlist/details/${item.notificationBodyParsed.entityId}`
              );
            } else if (item.notificationBodyParsed.audienceId) {
              this.getAudienceAction(item.notificationBodyParsed.audienceId);
            } else if (item.notificationBodyParsed.reportId) {
              this.downloadReport(item, false);
            } else {
              this.openNotification();
              if (item.notificationGroupName === 'Agenda') {
                this.reloadRouter(
                  `/agenda/task/${item.notificationBodyParsed.queueName}/${item.notificationBodyParsed.entityType}/${item.notificationBodyParsed.entityId}/${item.notificationBodyParsed.type}/${item.notificationBodyParsed.taskId}`
                );
                this.agendaService.refreshTaskList.next('success');
              } else if (item.notificationGroupName === 'Campaign') {
                this.reloadRouter(
                  `/campaign/update/${item.notificationBodyParsed.campaignId}`
                );
              } else if (item.notificationGroupName === 'Opportunity') {
                if (item.notificationBodyParsed.iconName) {
                  this.reloadRouter(
                    `/opportunities/details/${
                      item.notificationBodyParsed.entityId
                    }/${item.notificationBodyParsed.iconName.split('_')[1]}`
                  );
                } else {
                  this.reloadRouter(
                    `/opportunities/details/${item.notificationBodyParsed.entityId}`
                  );
                }
              } else if (item.notificationGroupName === 'Lead') {
                this.reloadRouter(
                  `/leads/details/${item.notificationBodyParsed.entityId}`
                );
              } else if (item.notificationGroupName === 'Branch') {
                const userId = this.userInfoService.getUserId();
                const banks = this.userInfoService.getBanks();
                let storedBank =
                  JSON.parse(
                    this.localStorageService.getItem(`selectedBank_${userId}`)
                  ) || banks[0];
                const bankId = item.notificationBodyParsed.bankID;
                if (storedBank.id == bankId) {
                  this.reloadRouter(`/settings/branches`);
                } else {
                  let notificationBank!: Bank;
                  banks.forEach((bank: Bank) => {
                    if (String(bank.id) == bankId) {
                      notificationBank = bank;
                    }
                  });
                  this.localStorageService.setItem(
                    `selectedBank_${userId}`,
                    JSON.stringify(notificationBank)
                  );
                  sessionStorage.setItem('pathName', '/settings/branches');
                  // this.changeLanguage(notificationBank.languages[0]);
                }
              } else if (item.notificationGroupName === 'Activity') {
                if (
                  item.notificationBodyParsed.entityType?.toLowerCase() ===
                  'lead'
                ) {
                  this.headerService
                    .checkNotificationStatus(
                      item.notificationBodyParsed.entityId
                    )
                    .subscribe(
                      (res: any) => {
                        if (res.statusCode === 200) {
                          this.reloadRouter(
                            `/${
                              res.entityType === 'opportunity'
                                ? 'opportunities'
                                : 'leads'
                            }/details/${res.entityId}/overview/${
                              item.notificationBodyParsed?.type
                            }`
                          );
                        }
                      },
                      (err: any) => {
                        this.toasterService.error(err);
                      }
                    );
                } else {
                  this.reloadRouter(
                    `/opportunities/details/${item.notificationBodyParsed.entityId}/overview/${item.notificationBodyParsed.type}`
                  );
                }
              } else if (item.notificationGroupName === 'RiskAlerts') {
                if (item.notificationBodyParsed.notificationType === riskAlertsNotificationTypes.RISK_ALERTS_ISSUED 
                  || item.notificationBodyParsed.notificationType === riskAlertsNotificationTypes.RISK_ALERTS_UPDATED) {
                  this.reloadRouter(
                    `/myaccounts/details/${item.notificationBodyParsed.entityId}/riskalerts`
                  );
                } else if (item.notificationBodyParsed.notificationType === riskAlertsNotificationTypes.RISK_ALERTS_COMMUNICATION_RECEIVED) {
                  this.reloadRouter(
                    `/myaccounts/details/${item.notificationBodyParsed.entityId}/riskalerts/communicationlogs`
                  );
                }
              } else if (item.notificationGroupName === 'RiskAlertsBatch') {
                this.viewRiskAlertsBatchDetails(item);
              }
            }
          }
          this.dataSharingService.updateNotificationData(item);
        }
      });
  }

  autoDownloadNotifications = () => {
    const autonotificationDownLaodList = this.notificationResultList.filter(
      (val) => val.notificationGroupName === 'DownloadGroup' && !val.isVisited
    );
    autonotificationDownLaodList.forEach((item: Notification) => {
      this.downloadReport(item, true);
    });
  };

  getAudienceAction = (audienceId: number) => {
    if (this.subscriptionAudienceActionData) {
      this.subscriptionAudienceActionData.unsubscribe();
    }
    this.subscriptionAudienceActionData = this.audienceService
      .getAudienceAction(this.bankInfoService.getBankId(), audienceId)
      .subscribe(
        (data: AudienceActionResponse) => {
          this.openNotification();
          this.validateAction(audienceId, data);
        },
        (error) => {}
      );
  };

  validateAction = (audienceId: number, data: AudienceActionResponse) => {
    if (data.errorCode === '0') {
      if (
        data.actions.length === 1 &&
        data.actions[0] === 'NO ALLOWED ACTION'
      ) {
        this.reloadRouter(`/audience/details/${audienceId}`);
      } else {
        this.reloadRouter(`/audience/view/${audienceId}`);
      }
    } else {
      this.toasterService.error(errorMessage);
    }
  };

  reloadRouter = (url: string): void => {
    this.router.navigateByUrl('/').then(() => {
      this.router.navigate([url]);
    });
  };
  downloadReport = (item: Notification, isAutoDownload: boolean) => {
    item.isLoading = true;
    if (item.notificationBodyParsed && item.notificationBodyParsed.reportId) {
      this.headerService
        .getReportData(item.notificationBodyParsed.reportId)
        .subscribe((data: any) => {
          if (data.errorCode === '0') {
            item.isLoading = false;
            if (
              window.navigator &&
              (window.navigator as any).msSaveOrOpenBlob
            ) {
              // download Excel in IE
              const byteChar = atob(data.report.fileData);
              const byteArray = new Array(byteChar.length);
              for (let i = 0; i < byteChar.length; i++) {
                byteArray[i] = byteChar.charCodeAt(i);
              }
              const uIntArray = new Uint8Array(byteArray);
              const blob = new Blob([uIntArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
              });
              (window.navigator as any).msSaveOrOpenBlob(
                blob,
                `${data.report.reportType}.xlsx`
              );
            } else {
              const mediaType =
                'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
              const source = `${mediaType}${data.report.fileData}`;
              const link = document.createElement('a');
              link.href = source;
              link.download = `${data.report.reportType}.${
                data.report.reportType == 'LEAD_REPORT' ||
                data.report.reportType == 'OPPORTUNITY_REPORT'
                  ? 'csv'
                  : 'xlsx'
              }`;
              link.click();
              setTimeout(() => {
                link.remove();
              }, 100);
            }
            if (isAutoDownload) {
              this.readNotification(item, true);
            }
          } else {
            item.isLoading = false;
            this.toasterService.error(
              data?.message || data?.applicationResponse || ''
            );
          }
        });
    }
  };

  getRiskAlertsTitleText = (notificationData: Notification) => {
    let riskAlertsHeadingText: string = '';
    let riskAlertsHeadingTextContent:any = [];
    if (notificationData.notificationGroupName === 'RiskAlerts') {
      riskAlertsHeadingTextContent = [...riskAlertsHeadingContent];
    } else if (notificationData.notificationGroupName === 'RiskAlertsBatch') {
      riskAlertsHeadingTextContent = [...riskAlertsBatchHeadingContent];
    }
    if (notificationData && notificationData.notificationBodyParsed?.notificationType) {
      let notificationType = notificationData.notificationBodyParsed?.notificationType;
      let riskAlertsHeadingTextItem = riskAlertsHeadingTextContent.find((item: any) => item.notificationType === notificationType);
      if (riskAlertsHeadingTextItem && riskAlertsHeadingTextItem.headingText) {
        riskAlertsHeadingText = riskAlertsHeadingTextItem.headingText;
      }
    }
    return riskAlertsHeadingText;
  };

  viewRiskAlertsBatchDetails = (notificationData: Notification) => {
    const dialogRef = this.dialog.open(RiskAlertsBatchViewDetailsComponent, {
      width: '70vw',
      maxWidth: '88vw',
      height: '80vh',
      maxHeight: '88vh',
      data: {
        notificationData: notificationData
      }
    });
  };
}
