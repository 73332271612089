import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mobileMenuList } from '@app/appConstants';
import { Bank, Menu, MenuItem, UserInfo } from '@app/model/interfaces/menu';
import { DataSharingService } from '@app/shared/services/data-sharing.service';
import { HeaderService } from '@app/shared/services/header.service';
import { LanguageService } from '@app/shared/services/language.service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { UserInfoService } from '@app/shared/services/user-info.service';
import { compare, getEncodingURL } from '@app/shared/utils/functions';
import { TranslateService } from '@ngx-translate/core';
import { BankInfoService } from '@shared/services/bank-info.service';
import { ToasterService } from '@shared/services/toaster.service';
import { Subscription, Subject } from 'rxjs';
import { SettingsService } from '@app/features/settings/settings.service';
import { userPrivilege } from '@app/model/interfaces/user-management';
import { SharedService } from '@app/shared/shared.service';

@Component({
  selector: 'clover-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  banksList: Bank[] = [];
  filteredBanksList: Bank[] = [];
  menuActive = false;
  bankSearchSelectionPopupActive = false;
  userId = this.userInfoService.getUserId();
  bankSearchTxt = '';
  selectedBank?: Bank;
  selectedBankId: number | undefined;
  profileImageUrl: string | undefined;
  userName: string | undefined;
  bankListSubscription!: Subscription;
  profileImageSubscription!: Subscription;
  hasImage: boolean | undefined;
  displayBankSelector = false;
  selectedLanguage = this.userInfoService.getSelectedLanguage();
  languageList!: string[];
  showMoaLabel = false;
  moaRoleArray = this.settingService.getUserPrivilege();
  moaRole!: string;
  mobileMenuList: Menu[] = mobileMenuList;
  showOnDesktop: boolean = this.userInfoService.getUserDevice();
  searchTerm: string | undefined;
  hasSettingPageAccess = false;
  hideNotificationPanel: Subject<boolean> = new Subject<boolean>();
  searchInfoError!: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public dataSharing: DataSharingService,
    private bankInfoService: BankInfoService,
    private localStorageService: LocalStorageService,
    private userInfoService: UserInfoService,
    private toasterService: ToasterService,
    private translate: TranslateService,
    private language: LanguageService,
    private headerService: HeaderService,
    private settingService: SettingsService,
    private sharedService: SharedService
  ) {
    if (!this.showOnDesktop) {
      const menus: MenuItem[] = this.userInfoService.getMenu();
      this.mobileMenuList.forEach((value) => {
        const menuItem = menus.find(
          (obj: MenuItem) => obj.name === value.name && obj.access === true
        );
        value.access = menuItem?.access;
      });
    }

    this.profileImageSubscription =
      this.dataSharing.profileImageChanged.subscribe({
        next: (res: string) => {
          this.profileImageUrl = res;
          this.hasImage = this.profileImageUrl ? true : false;
        },
      });
    this.bankListSubscription = this.dataSharing.bankListChanged.subscribe({
      next: (res: UserInfo) => {
        if (res) {
          this.banksList = res.banks.sort(compare('name', 'string'));
          this.filteredBanksList = this.banksList;
          let storedBank =
            JSON.parse(
              this.localStorageService.getItem(`selectedBank_${this.userId}`)
            ) || this.userInfoService.getBanks()[0];
          const bankCopy = this.banksList.filter(
            (bank) => bank.id === storedBank.id
          );
          this.languageList = storedBank.languages
            ? storedBank.languages
            : bankCopy[0].languages;
          if (storedBank) {
            const bankList = this.banksList.filter(
              (bank: Bank) => bank.id === parseInt(storedBank.id, 10)
            );
            storedBank = bankList.length ? bankList[0] : null;
          }
          if (!storedBank) {
            this.selectedBank = this.banksList[0];
            this.selectedBankId = this.selectedBank.id;
            this.localStorageService.setItem(
              `selectedBank_${this.userId}`,
              JSON.stringify(this.selectedBank)
            );
          } else {
            this.selectedBank = this.banksList.filter(
              (bank) => bank.id === parseInt(storedBank.id, 10)
            )[0];
            this.selectedBankId = storedBank.id;
          }
          this.bankInfoService.setBanksList(this.banksList);
          if (this.selectedBank) {
            this.bankInfoService.setBankId(this.selectedBank);
          }

          if (res.profileImageUrl) {
            this.profileImageUrl = res.profileImageUrl;
            this.userName = res.userId;
            this.hasImage = true;
          }
        }
        this.displayBankSelector = res.displayBankSelector;
      },
      error: (error) => {},
      complete: () => {},
    });

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.showMoaLabel = event.urlAfterRedirects.includes('/moa');
        this.getMoaRole();
        const searchText = localStorage.getItem(`globalSearchText`) || '';
        this.searchTerm = event.url == '/searchResults' ? searchText : '';
      }
    });
  }

  ngOnInit(): void {
    const userData = this.userInfoService.getUserAuthInfo();
    if (userData && userData.firstName) {
      this.userName = userData.firstName;
    }
    if (this.router.url === '/searchResults') {
      const searchText = localStorage.getItem(`globalSearchText`);
      if (searchText) {
        this.searchTerm = searchText;
      }
    }

    if (this.selectedBank) {
      this.bankInfoService.setBankId(this.selectedBank);
    }

    const menus: MenuItem[] = this.userInfoService.getMenu();
    menus.forEach((value) => {
      if (value.name === 'settings') {
        this.hasSettingPageAccess = value.access;
      }
    });
  }
  getMoaRole = () => {
    if (this.showMoaLabel) {
      const UserMoaRole = this.sharedService.getProfileDetails().moaPrivilege;
      if (UserMoaRole) {
        const role = this.moaRoleArray.filter(
          (item: userPrivilege) => item.id === UserMoaRole
        );
        if (role.length) {
          this.moaRole = role[0].privilegeDisplayName;
        }
      }
    }
  };

  getEncodingURL = (imagePath: string | undefined) => getEncodingURL(imagePath);
  getLangauge = (language: string): string => language.split('-')[0];

  changeLanguage(language: string): void {
    this.userInfoService.setUserLanguage(language).subscribe((res) => {
      if (res.errorCode === '0') {
        this.selectedLanguage = language;
        this.language.setLanguage(language);
        this.localStorageService.setItem('getSelectedLanguage', language);
        window.location.reload();
      }
    });
  }
  setMenuToggle = () => (this.menuActive = !this.menuActive);
  setBankSearchSelectionPopupActive = (value: boolean) => {
    this.bankSearchSelectionPopupActive = value;
    this.filterBankList();
  };
  onBankChange(item: Bank): void {
    this.bankSearchTxt = '';
    this.filteredBanksList = this.banksList;
    this.setBankSearchSelectionPopupActive(false);
    this.selectedBank = item;
    this.bankInfoService.setBankId(this.selectedBank);
    this.localStorageService.setItem(
      `selectedBank_${this.userId}`,
      JSON.stringify(this.selectedBank)
    );
    this.changeLanguage(item.languages[0]);
  }

  filterBankList(): void {
    this.filteredBanksList = this.banksList.filter(
      (bank) =>
        bank.name.toLowerCase().indexOf(this.bankSearchTxt.toLowerCase()) !==
          -1 && this.selectedBank?.id !== bank.id
    );
  }
  onClickSearch(): void {
    if (this.searchTerm && this.searchTerm.length >= 3) {
      this.router.navigate([`/searchResults`]);
      localStorage.setItem(`globalSearchText`, this.searchTerm);
      this.headerService.globalSearchTextChanged.next(this.searchTerm);
    } else {
      this.translate
        .get('shared.search.info.error')
        .subscribe((text: string) => (this.searchInfoError = text));
      this.toasterService.error(this.searchInfoError);
    }
  }

  logout(): void {
    const userAuthInfo = this.userInfoService.getUserAuthInfo();
    this.userInfoService.logout().subscribe(
      () => (window.location.href = userAuthInfo.redirectUrl),
      () => (window.location.href = userAuthInfo.redirectUrl)
    );
  }
  outsideClick = (value: boolean) => {
    if (value) {
      this.hideNotificationPanel.next(value);
    }
  };

  restrictSpecialChar = (event: KeyboardEvent) => {
    return event.charCode != 37;
  };

  removeSpecialChar = () => {
    this.searchTerm = this.searchTerm
      ? this.searchTerm.replace(/\%|/g, '')
      : '';
  };

  ngOnDestroy(): void {
    if (this.bankListSubscription) {
      // this.bankListSubscription.next(true);
      this.bankListSubscription.unsubscribe();
    }
  }
}
